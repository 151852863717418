import React from 'react'
import { Modal, Row, Col, Button, } from 'react-bootstrap'


const TallyModal = ({ products, total, email, show, handleClose }) => {

    const tallyAsText = () => {
        const textString = products.reduce((acc, el, index, arr) => {
            if(index === (arr.length - 1)){
                acc = acc + "and " + el.title + "."
            } else {
                acc = acc + el.title + ", "
            }
            return acc;
        }, "");
        const textField = document.createElement('textarea')
        textField.innerText = textString;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        alert("COPIED");

        // const element = document.createElement("a");
        // const file = new Blob([textString], {type: 'text/plain'});
        // element.href = URL.createObjectURL(file);
        // const dateObj = new Date();
        // element.download = `${email}-sold-products-${dateObj.getUTCMonth() + 1}/${dateObj.getUTCDate()}/${dateObj.getUTCFullYear()}-${dateObj.getHours()}:${dateObj.getMinutes()}.txt`;
        // document.body.appendChild(element);
        // element.click();
    }

    return (
        <Modal size="m" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Amount Owed To {email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    products.map((product) => {
                        return <Row key={product.id} className="justify-content-between m-1">
                            <Col>
                                <Row>
                                    {product.title}
                                </Row>
                            </Col>
                            <Col>
                                <Row className="justify-content-end">
                                    {product.variants[0] && product.variants[0].price && (+product.variants[0].price).toFixed(2)}
                                </Row>

                            </Col>
                        </Row>
                    })
                }
                <Row className="justify-content-between m-1">
                    <Col>
                        <Row className="justify-content-end mr-1">
                            Total:
                        </Row>
                    </Col>
                    <Col>
                        <Row> 
                            {total.toFixed(2)}
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-between m-1">
                    <Col>
                        <Row className="justify-content-end mr-1">
                            <a target="_blank" href="https://ctrl-mod.myshopify.com/admin/gift_cards/new">Store Credit:</a>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <a target="_blank" href="https://ctrl-mod.myshopify.com/admin/gift_cards/new">{(total*0.8).toFixed(2)}</a>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-between m-1">
                    <Col>
                        <Row className="justify-content-end mr-1">
                            Cash Owed:
                        </Row>
                    </Col>
                    <Col>
                        <Row> 
                            {(total*0.75).toFixed(2)}
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-between m-3">
                    <Col>
                        <Row className="justify-content-center">
                            <Button onClick={tallyAsText}>Copy Items To Clipboard</Button>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default TallyModal;