import React, { useState } from 'react'
import { Modal, Form, Row, Col, Button, ToggleButton } from 'react-bootstrap'
import { createNewCustomer } from '../repositories/customer';
import Alert from "react-bootstrap/Alert";
import { ButtonGroup } from 'react-bootstrap';


const CustomerForm = ({show, handleClose}) => {

    const [customer, setCustomer] = useState({});
    const [checked, setChecked] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);


    const setField = (field, value) => {
        setCustomer({
          ...customer,
          [field]: value
        })
    }

    const submit = () => {
        customer.note = `${customer.id_type} ${customer.country} ${customer.id_number}`  
        createNewCustomer({customer}).then((customer) => {  
            setError(false);
            setSuccess(true);
            document.body.scrollTop = 0; 
            document.documentElement.scrollTop = 0;    
            setTimeout(() => {
                window.location.reload();
            }, 2500)
        })
        .catch((error) => {
            document.body.scrollTop = 0; 
            document.documentElement.scrollTop = 0;             
            setError(true);
            setSuccess(false);
        });
    }

    const updateToggle = (type, value) => {
        setChecked(type);
        setField("id_type", type);
    }

    return(
        <Modal size="xl" show={show} onHide={() => { handleClose(); setError(false); setSuccess(false); }}>
            {
                (error || success) &&
                <Alert variant={success ? "success" : "danger"}>
                {
                    success ? 
                    "you  successfully added the customer you can navigate away from this page or input more customers" :
                    "an error occurred, the customer did not get added. Phone numbers must be unique, no two users can have the same phone number, also make sure that you put in the state/province as capital two letters."
                }
                </Alert>
            }
            <Modal.Header closeButton>
                <Modal.Title>New Customer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control 
                                    onChange={ e => setField('first_name', e.target.value) }
                                    type="text" 
                                    placeholder="First name" 
                                />
                            </Col>
                            <Col>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Last name" 
                                    onChange={ e => setField('last_name', e.target.value) }
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email" 
                                    onChange={ e => setField('email', e.target.value) }
                                />
                            </Col>
                            <Col>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control 
                                    type="tel" 
                                    placeholder="Phone number" 
                                    onChange={ e => setField('phone', e.target.value) }
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>Address</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Address" 
                                    onChange={ e => setField('address', e.target.value) }
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>City</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="City" 
                                    onChange={ e => setField('city', e.target.value) }
                                />
                            </Col>
                            <Col>
                                <Form.Label>State/Province</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="State/Province" 
                                    onChange={ e => setField('province', e.target.value) }
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>Zip</Form.Label>
                                <Form.Control 
                                    type="text"
                                    placeholder="Zipcode" 
                                    onChange={ e => setField('zip', e.target.value) }
                                />
                            </Col>
                            <Col>
                                <Form.Label>Country</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Country" 
                                    onChange={ e => setField('country', e.target.value) }
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Row className="align-items-center justify-content-center">
                            <Col>
                                <ToggleButton
                                    className="mr-1"
                                    type="radio"
                                    variant="primary"
                                    checked={checked === "license"}
                                    value="license"
                                    onChange={(e) => updateToggle("license", e.currentTarget.checked)}
                                >
                                    License
                                </ToggleButton>
                                <ToggleButton
                                    type="radio"
                                    variant="primary"
                                    checked={checked === "passport"}
                                    value="passport"
                                    onChange={(e) => updateToggle("passport", e.currentTarget.checked)}
                                >
                                    Passport
                                </ToggleButton>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>State/Country</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="State/Country" 
                                    onChange={ e => setField('country', e.target.value) }
                                />
                            </Col> 
                            <Col>
                                <Form.Label>License/Passport No</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="License/Passport Number" 
                                    onChange={ e => setField('id_number', e.target.value) }
                                />
                            </Col> 
                        </Row>
                    </Form.Group>
                </Form>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button onClick={submit} variant="primary">Submit</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default CustomerForm;
