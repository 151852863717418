import React from 'react'
import ProductItem from './ProductItem'
import { Container, Col, Row } from 'react-bootstrap'

const ProductList = ({ products, showSetToLive = false, showReturnButton, shouldDisable, shouldDisableNotes }) => {

    return(
            <Container>
                <Row>
                    <Col>
                        {   
                            products && products.length > 0 && products.map((product) => {
                                return <ProductItem shouldDisable={shouldDisable} shouldDisableNotes={shouldDisableNotes} showReturnButton={showReturnButton} showSetToLive={showSetToLive} key={product.id} product={product}></ProductItem>
                            })
                        }
                    </Col>
                </Row>
            </Container>
    )
}

export default ProductList;
