import React, { useEffect, useState } from 'react';
import {  getProductsToPost, getPaginationProducts } from '../repositories/products';
import ProductList from '../components/ProductList';
import Loader from '../components/Loader';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const ProductsToPost = () => {

    const [products, setProducts] = useState([]);
    const [nextPage, setNextPage] = useState(true);
    const [prevPage, setPrevPage] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProductsToPost().then((response) => {
            setProducts(response.data.products.reverse());
            if(response.data && response.data.pagination){
                setNextPage(response.data.pagination.nextPage);
                setPrevPage(response.data.pagination.prevPage);
                setLoading(false)
            }
        })
    },[])

    const paginate = (dir) => {
        getPaginationProducts(dir).then((response) => {
            setProducts(response.data.products.reverse());
            if(response.data && response.data.pagination){
                setNextPage(response.data.pagination.nextPage);
                setPrevPage(response.data.pagination.prevPage);
            }
        })
    }
    
    const bespoke = () => {
        const url = "https://ctrl-mod.myshopify.com/admin/apps/custom-shipping-rates";
        window.open(url,'_blank');
    }

    return(
        <>
        {
            loading ? <Loader/> :          
            <Container>
                <Row className="align-items-center justify-content-center" xs={2}>
                    <Button size="sm" onClick={bespoke}>
                        Bespoke Shipping --> Settings --> Reload Collections
                    </Button>
                </Row>
                <h1 className="m-3">Products To Post</h1>
                    <Row>
                        <Col>
                            <ProductList showReturnButton={true} showSetToLive={true} products={products}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                nextPage &&
                                <Button onClick={() => paginate("next")} className="m-3">next</Button>
                            }
                            {
                                prevPage &&
                                <Button onClick={() => paginate("prev")} className="m-3">prev</Button>
                            }
                        </Col>
                    </Row>
            </Container>
        }
        </>
    )

}

export default ProductsToPost;