import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Button, FormControl } from 'react-bootstrap'
import Alert from "react-bootstrap/Alert";

const ReturnModal = ({ product, show, handleClose, submit, error, success }) => {

    const [product_id, setProductId] = useState(null);
    const [notes, setNotes] = useState("");
    const [email, setEmail] = useState(null);
    const [variants, setVariants] = useState(null);

    const isValidJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    useEffect(() => {
        const email = isValidJson(product.metafields) && JSON.parse(product.metafields) && JSON.parse(product.metafields).email
        setProductId(product.id);
        setEmail(email);
        setVariants(product.variants);
    }, [product]);


    const getNotes = () => {
        if(isValidJson(product.metafields) && JSON.parse(product.metafields) && JSON.parse(product.metafields).notes){
            const currentNotes = JSON.parse(product.metafields).notes;
            if(Array.isArray(currentNotes)){
                return currentNotes.map((note) => {
                    return `${note.notes}\n`;
                }).join("");
            } else {
                return currentNotes
            }
        } 
    }

    return(
        <Modal size="xl" show={show} onHide={handleClose}>
            {
                (error || success) &&
                <Alert variant={success ? "success" : "danger"}>
                {
                    success ? 
                    "you successfully set this product to returned status" :
                    "an error occurred."
                }
                </Alert>
            }
            <Modal.Header closeButton>
                <Modal.Title>Update Product to Returned Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Row>
                            <Col>

                                <Form.Label>Add Note</Form.Label>
                                <FormControl 
                                    as="textarea" 
                                    rows="10"
                                    aria-label="With textarea" 
                                    onChange={(e) => setNotes(e.target.value)}
                                    defaultValue={getNotes()}
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => submit({ product_id, notes, email, variants })} variant="primary">Submit</Button>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}

export default ReturnModal;
