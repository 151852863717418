import React, { useEffect, useState, useRef } from 'react'
import { Container } from 'react-bootstrap'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import Loader from '../components/Loader'
import ProductItem from '../components/ProductItem'
import { graphSearchSoldUsed, graphSearchUsed } from '../repositories/products'
import TallyModal from '../components/TallyModal'


const UserSearch = () => {

    const [liveProducts, setLiveProducts] = useState([]);
    const [soldProducts, setSoldProducts] = useState([]);
    const [toPostProducts, setToPostProducts] = useState([]);
    const [returnedProducts, setReturnedProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastUserEmail, setLastUserEmail] = useState("");
    const [email, setEmail] = useState("");


    const [showTallyModal, setShowTallyModal] = useState(false);
    const [total, setTotal] = useState(0);


    const search_used = () => {
        setLoading(true);
        if(lastUserEmail !== email){
            setSoldProducts([]);
            setLiveProducts([]);
            setReturnedProducts([]);
            setToPostProducts([]);
        }
        let search_timer;
        const start_timer = () => {
            search_timer = setTimeout(() => {
                search_used();
            }, 1000*5)
        }
        graphSearchUsed(email)
        .then((response) => {
            if(response.data.progress === "working"){
                start_timer();
            } else {
                setLiveProducts(response.data.live);
                setReturnedProducts(response.data.returned);
                setToPostProducts(response.data.toPost); 
                setLastUserEmail(email);
                clearTimeout(search_timer);
                setLoading(false);
            }
        })
        .catch((error) => {
            console.log(error);
            alert("something went wrong!")
            setLoading(false);
        });
    }

    const search_sold_used = () => {
        setLoading(true);
        if(lastUserEmail !== email){
            setSoldProducts([]);
            setLiveProducts([]);
            setReturnedProducts([]);
            setToPostProducts([]);
        }
        let search_used_timer;
        const start_used_timer = () => {
            search_used_timer = setTimeout(() => {
                search_sold_used();
            }, 1000*5)
        }
        graphSearchSoldUsed(email).then((response) => {
            if(response.data.progress === "working"){
                start_used_timer();
            } else {
                const sold = response.data.sold.sort((a,b) => {
                    return new Date(b.updated_at) - new Date(a.updated_at);
                });
                setSoldProducts(sold);
                clearTimeout(search_used_timer);
                setLastUserEmail(email);
                setLoading(false);
            };
        })
        .catch((error) => {
            console.log(error);
            alert("something went wrong!")
            setLoading(false);
        });
    }

    const tallyOwed = () => {
        const total = soldProducts.reduce((acc, el) => {return acc + +el.variants[0].price } , 0)
        setTotal(total);
        setShowTallyModal(true);
    }

    const handleClose = () => setShowTallyModal(false)

    const handleKeyPress = (e) => {
        if(e.code == 'Enter'){
            search_used();
        } 
    }

    return(
        loading ? <Loader/> :    
        <Container>
            <Row >
                <Col className="mb-3">
                    <InputGroup className="mb-3">
                            <FormControl
                                className="mr-2"
                                placeholder={email ? email : "Search By User E-mail"}
                                onChange={((e) => setEmail(e.target.value.trim()))}
                                onKeyPress={handleKeyPress}
                            />
                            <Button className="mr-2" onClick={() => search_used()}>
                                Search Used
                            </Button>
                            <Button onClick={() => search_sold_used()}>
                                Search Sold Used
                            </Button>
                    </InputGroup>
                </Col>
            </Row>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between mb-2">
                        <Col>
                            <Card.Title>Sold</Card.Title>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <Button className="mr-2" onClick={tallyOwed}>
                                Tally Owed
                            </Button>
                        </Col>
                    </Row>  
                    <ListGroup>
                        {
                            soldProducts && soldProducts.length > 0 ?
                                soldProducts.map(product => {
                                    return <Row key={product.id}>
                                        <Col>
                                            <ProductItem 
                                                showSetToLive={true} 
                                                product={product}
                                                refresh={false}
                                            />
                                        </Col>
                                    </Row>}) : 
                                <ListGroup.Item>No Sold Items</ListGroup.Item>
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between m-2">
                        <Card.Title>Live</Card.Title>
                    </Row>
                    <ListGroup>
                        {
                            liveProducts.length > 0 ? 
                                liveProducts.map( product => {
                                    return <Row key={product.id} className="w-100">
                                        <Col>
                                            <ProductItem 
                                                showReturnButton={true} 
                                                showSetToLive={true} 
                                                product={product}
                                                refresh={false}
                                            />
                                        </Col>
                                    </Row>}) : 
                                <ListGroup.Item>No Live Items</ListGroup.Item>
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between m-2">
                        <Card.Title>Returned</Card.Title>
                    </Row>
                    <ListGroup>
                        {
                            returnedProducts.length > 0 ? 
                                returnedProducts.map( product => {
                                    return <Row key={product.id} className="w-100">
                                        <Col>
                                            <ProductItem 
                                                shouldDisable={true}  
                                                product={product}
                                                showSetToLive={true}
                                                refresh={false}
                                            />
                                        </Col>
                                    </Row>}) : 
                                <ListGroup.Item>No Returned Items</ListGroup.Item>
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="justify-content-between m-2">
                        <Card.Title>To Post</Card.Title>
                    </Row>
                    <ListGroup>
                        {
                            toPostProducts.length > 0 ? 
                                toPostProducts.map( product => {
                                    return <Row key={product.id} className="w-100">
                                        <Col>
                                            <ProductItem 
                                                product={product} 
                                                showReturnButton={true}
                                                showSetToLive={true}
                                                searchPage={true}
                                                refresh={false}
                                            />
                                        </Col>
                                    </Row>
                                    }) : 
                                <ListGroup.Item>No Items to Post</ListGroup.Item>
                        }
                    </ListGroup>
                </Card.Body>
            </Card>
            <TallyModal
                show={showTallyModal}
                products={soldProducts}
                total={total}
                email={email}
                handleClose={handleClose}
            />
      </Container>

    )

}

export default UserSearch;