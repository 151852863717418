import axios from 'axios';

export const graphSearchUsed = (email) => {
  return axios.get(`/search?email=${email}&collection=used`);
};

export const graphSearchSoldUsed = (email) => {
  return axios.get(`/search?email=${email}&collection=sold_used`);
};


export const getLiveProducts = () => {
  return axios.get("/products?status=active");
};

export const getSoldProducts = () => {
  return axios.get("/products?status=active&sold=true");
};

export const getProductsToPost = () => {
  return axios.get("/products?status=draft"); 
};

export const getReturnedProducts = () => {
  return axios.get("/products?status=archived&returned=true"); 
};

export const getPaginationProducts = (dir) => {
    return axios.get(`/productsPaginate?dir=${dir}`)
};

export const setProductToLive = ({ product_id }) => {
    return axios.get(`/setProductToLive?product_id=${product_id}`); 
};

export const setProductToReturned = ({ product_id, notes, email, variants }) => {
  return axios.post(`/setProductToReturned`, {
    product : {
      product_id,
      notes,
      email,
      variants
    }
  });
};

export const updateProduct = ({ updatedProduct }) => {
  return axios.post("/updateProduct", {
    product : {
      id: updatedProduct.id,
      title: updatedProduct.title,
      description: updatedProduct.description,
      price: updatedProduct.price,
      weight: updatedProduct.weight,
      variants : updatedProduct.variants,
      notes: updatedProduct.notes,
      email: updatedProduct.email,
      metafield_id : updatedProduct.metafield_id,
      images: [
          {
              attachment: updatedProduct.images,
          },
      ]
    }
  });
}



export const intakeProduct = ({ intake }) => {
    return axios.post("/intakeProduct", {
      product : {
        title: intake.title,
        description: intake.description,
        price: intake.price,
        customer_email: intake.user_email,
        signature: intake.signature,
        identifier: intake.identifier,
        notes: [{email: localStorage.getItem('user'), notes: "product added", timeStamp: new Date()}],
        sendEmail: false,
        images: [
            {
                attachment: intake.images
            },
        ]
      }
    });
  }

  export const intakeProductFinal = ({ intake }) => {
    return axios.post("/intakeProduct", {
      product : {
        title: intake.title,
        description: intake.description,
        price: intake.price,
        customer_email: intake.user_email,
        signature: intake.signature,
        notes: [{email: localStorage.getItem('user'), notes: "product added", timeStamp: new Date()}],
        sendEmail: true,
        productAmt: intake.productAmt,
        identifier: intake.identifier,
        descriptions: intake.descriptions,
        links: intake.links,
        images: [
            {
                attachment: intake.images
            },
        ]
      }
    });
  }
  