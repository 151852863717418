import React, { useEffect, useState } from 'react'
import { getLiveProducts, getPaginationProducts } from '../repositories/products'
import ProductList from '../components/ProductList'
import Loader from '../components/Loader'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


const LiveProducts = () => {

    const [products, setProducts] = useState([]);
    const [nextPage, setNextPage] = useState(true);
    const [prevPage, setPrevPage] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getLiveProducts().then((response) => {
            setProducts(response.data.products);
            if(response.data && response.data.pagination){
                setNextPage(response.data.pagination.nextPage);
                setPrevPage(response.data.pagination.prevPage);
                setLoading(false)
            }
        })
    },[])

    const paginate = (dir) => {
        getPaginationProducts(dir).then((response) => {
            setProducts(response.data.products);
            setNextPage(response.data.pagination.nextPage);
            setPrevPage(response.data.pagination.prevPage)
        })
    }

    return(
        <>
        {
            loading ? <Loader/> :          
            <Container>
                <h1 className="m-3">Live Products</h1>
                    <Row>
                        <Col>
                            <ProductList showReturnButton={true} showSetToLive={true} products={products}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                nextPage &&
                                <Button onClick={() => paginate("next")} className="m-3">next</Button>
                            }
                            {
                                prevPage &&
                                <Button onClick={() => paginate("prev")} className="m-3">prev</Button>
                            }
                        </Col>
                    </Row>
            </Container>
        }
        </>
    )

}

export default LiveProducts;