import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Container, Navbar, Nav, NavDropdown, Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import CustomerForm from '../components/CustomerForm';

const NavBar = () => {
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [email, setEmail] = useState(null);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleOpenMenu = () => setMenuOpen(true);
    const handleCloseMenu = () => setMenuOpen(false);

    const openWiggle = () => {
        const url = "https://wigglehunt.com/?query=&condition=&stock_status=&price_min=0&price_max=5000&currency_code=150&sort=price";
        window.open(url,'_blank');
    }

    const emailLookUp = () => {
        const url = "https://ctrl-mod.myshopify.com/admin/customers";
        window.open(url,'_blank');
    }

    const logOut = () => {
        window.location.href = `https://ctrl-consignment-app-v2.herokuapp.com/logout` 
    }

    return(
        <>
            <Navbar expand="sm">
                <Container>
                    <Nav as="ul" className="m-3">
                        <Nav.Item as="li">
                            <Nav.Link href="/products-to-post">Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link href="/intake-product">Intake</Nav.Link>
                        </Nav.Item>
                        <NavDropdown 
                            title="Items" 
                            id="nav-dropdown" 
                            as="li" 
                            onMouseEnter={handleOpenMenu}
                            onMouseLeave={handleCloseMenu}
                            show={menuOpen}>
                            <NavDropdown.Item href="/sold-products">Sold</NavDropdown.Item>
                            <NavDropdown.Item href="/live-products">Live</NavDropdown.Item>
                            <NavDropdown.Item href="/returned-products">Returned</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Item as="li">
                            <Nav.Link onClick={handleShowModal}>Add Customer</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link onClick={emailLookUp}>Email Lookup</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link onClick={openWiggle}>Wigglehunt</Nav.Link>
                        </Nav.Item>                       
                        <Nav.Item as="li">
                            <Nav.Link href="/user-search?">Search</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link onClick={logOut}>Log Out</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Container>
            </Navbar>
            <CustomerForm show={showModal} handleClose={handleCloseModal}/>
        </>
    )
}

export default NavBar;
