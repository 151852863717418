import React, { useEffect, useState } from "react";
import { loggedIn } from "./repositories/login";
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import ProductsToPost from "./pages/products-to-post";
import LiveProducts from "./pages/live-products";
import SoldProducts from "./pages/sold-products";
import NavBar from './components/NavBar';
import IntakeProduct from "./pages/intake-product";
import UserSearch from "./pages/user-search";
import ReturnedProducts from "./pages/returned-products";


function App() {

  const [userLoggedIn, setUserLoggedIn] = useState(false);

  useEffect(() => {
    loggedIn().then((response) => {
     if(!response.data.loggedIn){
      //  window.location.href = "http://localhost:3001/login"
      window.location.href = "https://ctrl-consignment-app-v2.herokuapp.com/login";
      localStorage.removeItem('timeStamp');
    } else {
       setUserLoggedIn(true);
       localStorage.setItem("user", response.data.user.emails[0].value);       
       if(localStorage.getItem('timeStamp') == null){
        localStorage.setItem('timeStamp', new Date().getHours());
       }

       setInterval(() => {
        const login_time = localStorage.getItem('timeStamp');
        const now = new Date().getHours();
        const other_now = new Date().getMinutes();
        if(Math.abs(now - login_time) >= 10) { 
          localStorage.removeItem('timeStamp');
          window.location.href = `https://ctrl-consignment-app-v2.herokuapp.com/logout`
        }
      }, 1000);
     }
    })   
  }, [])

  return (
    <>
    {
        userLoggedIn ? 
            <Router>
              <NavBar/>
              <Switch>
                <Route path="/products-to-post">
                  <ProductsToPost/>
                </Route>
                <Route path="/intake-product">
                  <IntakeProduct/>
                </Route>
                <Route path="/live-products">
                  <LiveProducts/>
                </Route>
                <Route path="/sold-products">
                  <SoldProducts/>
                </Route>
                <Route path="/user-search">
                  <UserSearch/>
                </Route>
                <Route path="/returned-products">
                  <ReturnedProducts/>
                </Route>
                <Route path="/">
                  <Redirect to="/products-to-post"/>
                </Route>
              </Switch>
            </Router>
         : 
         !userLoggedIn && <div>YOU MUST LOGIN</div>
      }
    </>
  );
}

export default App;
