import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import CurrencyInput from 'react-currency-input-field';
import { getUserMetafield } from '../repositories/metafields'

import { formatDate } from '../utils/FormatDate'
import { setProductToLive, updateProduct } from '../repositories/products'
import { toBase64 } from "../utils/toBase64";
import { Card } from "react-bootstrap";

const ProductInfo = ({ product, show, handleClose, showSetToLive, shouldDisable, shouldDisableNotes, refresh }) => {

    const [updatedProduct, setUpdatedProduct] = useState({});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("an error occurred, the product did not get added, try that shit again. All products must have a title, if there is no title that is likely the issue");
    const firstUpdate = useRef(true);


    const isValidJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    useEffect(() => {
        const updatedProduct = JSON.parse(JSON.stringify(product));
        setError(false);
        updatedProduct.images = [];
        updatedProduct.metafield_id = product.metafield_id;
        updatedProduct.price = product.variants[0].price;
        updatedProduct.weight = product.variants[0].weight;
        // updatedProduct.notes = isValidJson(product.metafields) ? JSON.parse(product.metafields) && JSON.parse(product.metafields).notes[0] : "no notes";
        updatedProduct.email = isValidJson(product.metafields) ? JSON.parse(product.metafields) && JSON.parse(product.metafields).email : null;
        setUpdatedProduct(updatedProduct);
    }, [product])

    useEffect(() => {
        const id = product.id;
        if(product.variants[0].barcode){
            product.metafields = product.variants[0].barcode;
            // product.metafield_id = id;
        } else {
            getUserMetafield({id})
            .then((metafields) => {
                if(metafields && metafields.data && metafields.data.metafields){
                    const metafield = metafields.data.metafields.find((metafield) => metafield && metafield.key && metafield.key === 'consignment-notes-email');
                    const value = (metafield && metafield.value) || null;
                    const id = (metafield && metafield.id) || null;
                    product.metafields = value;
                    product.metafield_id = id;
                }
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [show])

    const setField = (field, value) => {
        setUpdatedProduct({
            ...updatedProduct,
            [field]: value
        })
    }


    const setNotes = (value) => {
        value.email = localStorage.getItem('user');
        const oldNotes = isValidJson(product.metafields) && JSON.parse(product.metafields) && JSON.parse(product.metafields).notes;
        let notes = [];
        if(Array.isArray(oldNotes)){
            if(oldNotes[0].noteFlag && oldNotes[0].noteFlag === "barcode"){
                let newTimeStamp = formatDate(new Date());
                newTimeStamp = newTimeStamp.split(":")[0];
                notes = [...oldNotes, { notes: value.notes, email: value.email, timeStamp: newTimeStamp } ]
            } else {
                notes = [...oldNotes, value]
            }
        } else {
           let newTimeStamp = formatDate(new Date());
           newTimeStamp = newTimeStamp.split(":")[0];
           notes = [
                { notes: oldNotes, noteFlag: "barcode" },
                { notes: value.notes, email: value.email, timeStamp: newTimeStamp  }
            ]; 
        }
        setField('notes', notes);
    }

    const uploadProductImage = (e) => {
        const file = e.target.files[0];
        toBase64(file).then((image) => {
            setField('images', image);
        });
        setField('images', e.target.files[0]);
    }

    const updateCurrentProduct = () => {
        updatedProduct.id = product.id;
        setError(false);
        updatedProduct.notes = updatedProduct.notes ? updatedProduct.notes : JSON.parse(product.metafields).notes;
        updatedProduct.email = updatedProduct.email ? updatedProduct.email : JSON.parse(product.metafields).email;
        updatedProduct.metafield_id = product.metafield_id;
        updateProduct({ updatedProduct }).then((product) => {
            if(refresh){
                window.location.reload();
            } else {
                handleClose()
            }
        })
        .catch((error) => {
            setError(true);
            setErrorMessage("an error occurred, the product did not get added, try that shit again. All products must have a title, if there is no title that is likely the issue");
            setSuccess(false);
            document.body.scrollTop = 0; 
            document.documentElement.scrollTop = 0; 
        });
    }
        
    const setToLive = () => {
        if(updatedProduct.price < 0.01 || updatedProduct.weight < 0.01 || !updatedProduct.title ){
            setError(true);
            setErrorMessage("Unable To Post. Missing Information")
        } else {
            updatedProduct.notes = updatedProduct.notes ? updatedProduct.notes : JSON.parse(product.metafields).notes;
            updatedProduct.email = updatedProduct.email ? updatedProduct.email : JSON.parse(product.metafields).email;
            updatedProduct.metafield_id = product.metafield_id;
            updateProduct({ updatedProduct }).then((product) => {
                handleClose();
            })
            .then(() => {
                setProductToLive({ product_id: product.id }).then((product) => {
                    const url = `https://ctrl-mod.myshopify.com/admin/apps/boxify/products/new?id=${product.data.product.id}`;
                    window.open(url,'_blank');
                    handleClose();
                    setTimeout(() => {window.location.reload();} , 3000);
                })
                .catch((error) => {
                    setError(true);
                    setErrorMessage("this item could not be added to shopify");
                    setSuccess(false);
                    document.body.scrollTop = 0; 
                    document.documentElement.scrollTop = 0;      
                })
            })
        }
    }

    const getNotes = () => {
        if(isValidJson(product.metafields) && JSON.parse(product.metafields) && JSON.parse(product.metafields).notes){
            const currentNotes = JSON.parse(product.metafields).notes;
            if(Array.isArray(currentNotes)){
                return currentNotes.map((note, i) => {
                    return (<Col style={{marginBottom:"16px"}}>
                        <Row style={{fontWeight:"bold"}}>
                            • {note.notes}
                        </Row>
                        <Row style={{fontSize: '12px', marginLeft:"10px"}}>
                            {note.email} {formatDate(note.timeStamp)}
                        </Row>
                    </Col>

                    )
                })
            } else {
                return <div>{currentNotes}</div>
            }
        } 
    }

    return(
        <>
            <Modal size="xl" show={show} onHide={() => { handleClose(); setError(false); setSuccess(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Button target="_blank" href={`https://ctrl-mod.myshopify.com/admin/products/${product.id}`}  variant="link">{product.title}</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
            
                <ListGroup.Item>{`User Email:  ${(isValidJson(product.metafields) ? 
                            JSON.parse(product.metafields) && 
                            JSON.parse(product.metafields).email : null)}`}</ListGroup.Item>
                <ListGroup.Item>{`Created on ${formatDate(product.created_at)}, last update: ${formatDate(product.updated_at)}`}</ListGroup.Item>

                <Form className="m-3">
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Weight</Form.Label>
                            <Form.Control 
                                disabled={shouldDisable}
                                type="text" 
                                placeholder="weight"
                                onChange={e => setField('weight', e.target.value)}
                                defaultValue={`${product.variants[0].weight}`}
                            />
                        </Col>

                        <Col>
                            <Form.Label>Price</Form.Label>
                            <CurrencyInput
                                disabled={shouldDisable}
                                className="form-control"
                                placeholder="$0.00"
                                decimalsLimit={2}
                                defaultValue={product.variants[0].price}
                                onValueChange={(value, name) => setField('price', value)}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Title</Form.Label>
                            <Form.Control 
                                disabled={shouldDisable}
                                type="text" 
                                placeholder="Title"
                                onChange={e => setField('title', e.target.value)}
                                defaultValue={product.title}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                disabled={shouldDisable}
                                as="textarea"
                                onChange={e => setField('description', e.target.value)}
                                defaultValue={product.body_html}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.File 
                                disabled={shouldDisable}
                                label="Update Image"
                                id="img" 
                                name="img" 
                                accept="image/*" 
                                onChange={e => uploadProductImage(e)} />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Add Notes</Form.Label>
                            <Form.Control 
                                disabled={shouldDisableNotes}
                                as="textarea"
                                onChange={e => setNotes({ notes: e.target.value, timeStamp: new Date() })}
                            />
                        </Col>
                        <Col>
                            <Form.Label>Notes</Form.Label>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            {
                                                getNotes()
                                            }                                        
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
                    {
                        showSetToLive &&
                        <Row className="justify-content-between m-3">
                            <Button disabled={shouldDisable} onClick={setToLive}>Post As Active On Site</Button>
                            <Button disabled={shouldDisableNotes} onClick={updateCurrentProduct}>Update Product</Button>
                        </Row>
                    }
                    {
                        (error || success) &&
                        <Alert variant={success ? "success" : "danger"}>
                        {
                            success ? 
                            "you  successfully input the product you can navigate away from this page or input more stuff" :
                            errorMessage
                        }
                        </Alert>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProductInfo;
