import axios from 'axios';


export const getUserMetafield = ({id}) => {
    return axios.get(`/userMetafield?id=${id}`);
};

export const getMetafieldsForProducts = ({products}) => {
    return axios.post(`/metafieldsForProducts`, {
        products
    });
};