import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Media from 'react-bootstrap/Media'
import Col from 'react-bootstrap/Col'
import ProductInfo from './ProductInfo'
import { formatDate } from '../utils/FormatDate'
import ReturnModal from './ReturnModal'
import { setProductToReturned } from '../repositories/products'

const ProductItem = ({ product, showSetToLive, showReturnButton, shouldDisable, shouldDisableNotes, refresh = true }) => {
    const [show, setShow] = useState(false);
    const [showReturned, setShowReturned] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleShowReturned = () => setShowReturned(true)
    const handleCloseReturned = () => setShowReturned(false)

    const getSmallImage = (image) => {
        const image_strings = image.split(".");
        image_strings[2] = image_strings[2] + "_small"
        return image_strings.join(".")
    }

    const goToDraft = (id) => {
        const url = `https://ctrl-mod.myshopify.com/admin/products/${id}`
        window.open(url,'_blank');
    }

    const set_to_returned = ({ product_id, notes, email, variants }) => {
        setProductToReturned({ product_id, notes, email, variants })
        .then((customer) => {
            setError(false);
            setSuccess(true);
            handleCloseReturned();
            window.location.reload();
        })
        .catch((error) => {
            setError(true);
            setSuccess(false);
        });
    }

    return(
        <Card>
            <Card.Body>
                <Row className="mb-3">
                    <Col className="ml-2">
                        <Row style={{flexWrap:"nowrap"}}>
                            <Media>
                                { product && product.image && 
                                    <img
                                        width={100}
                                        height={100}
                                        className="ml-4"
                                        src={getSmallImage(product.image.src)}
                                        alt={product.id}
                                    />
                                }
                            </Media>
                            <Card.Title style={{textAlign:'left'}}>
                                <Button style={{whiteSpace:'nowrap'}} onClick={() => goToDraft(product.id)} variant="link">{product.title}</Button>
                            </Card.Title>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="justify-content-end">
                    {
                        showReturnButton &&
                        
                            <Button className="mr-2" variant="primary" onClick={handleShowReturned}>Mark As Returned</Button>
                                        
                    }
                    {
                        showSetToLive && 
                        <Button variant="primary" onClick={handleShow}>Edit And Go Live</Button>
                    }
                        </Row>
                    </Col>
                </Row>
                <Row className="m-1 align-items-end">
                        <Card.Text className="mr-2 mb-0" style={{color: "green", fontWeight: 'bold'}}>
                            {`$${product.variants[0].price}`} 
                        </Card.Text>
                        <Card.Text className="mr-2 mb-0">{`Posted on: ${formatDate(product.created_at)}`}</Card.Text>
                </Row>
                <ProductInfo 
                    shouldDisable={shouldDisable} 
                    showSetToLive={showSetToLive} 
                    product={product} 
                    show={show} 
                    handleClose={handleClose} 
                    shouldDisableNotes={shouldDisableNotes}
                    refresh={refresh}
                >
                </ProductInfo>
                <ReturnModal 
                    error={error} 
                    success={success} 
                    submit={set_to_returned} 
                    product={product} 
                    show={showReturned} 
                    handleClose={handleCloseReturned}
                />
            </Card.Body>
        </Card>
    )
}

export default ProductItem;
