import axios from 'axios';

export const createNewCustomer = ({ customer }) => {
  return axios.post("/createCustomer", {
    customer : {
      first_name : customer.first_name,
      last_name : customer.last_name,
      email : customer.email,
      phone: customer.phone,
      address: customer.address,
      city: customer.city,
      province: customer.province,
      zip: customer.zip,
      country: customer.country,
      note: customer.note
    }
  });
}
