import React, { useState, useEffect, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row  from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import SignatureCanvas from 'react-signature-canvas';
import CurrencyInput from 'react-currency-input-field';
import { v4 as uuidv4 } from 'uuid';
import { intakeProduct, intakeProductFinal } from "../repositories/products";

const textInputDefaultValue = `Fully tested, perfect working condition. Minimal rack rash.<br><br>About Used Items:<br><br>- All modules include a power ribbon cable and M3 screws.<br>- Please be aware that although all used items have been tested to work as they are designed, they are sold "As Is" and are not accepted for returns unless there is a defect in the used item.<br>- Used items DO NOT qualify for Free Shipping, if you order a new item with a used item you will be charged shipping based on the weight and size ONLY of the used module.<br>- Used items can take 1-2 days longer to ship.`

const IntakeProduct = () => {

    const [intake, setIntake] = useState({});
    const [intakes, setIntakes] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);
    const [signature, setSignature] = useState(null);
    const [disableAgree, setdisableAgree] = useState(false);
    const titleRef = useRef(null);
    const description = useRef(null);


    let signPad = {}

    const setField = (field, value) => {
        setIntake({
            ...intake,
            [field]: value
        })
    }

    useEffect(() => {
        setField("description", textInputDefaultValue);
    }, [])

    const submitIntake = () => {
        setdisableAgree(true);
        let finalIntakes = [];
        if(intakes.length > 15){
            const sliceSize = 12;
            let currSlice = intakes.length - sliceSize;
            const firstSlice = intakes.slice(0, currSlice);
            finalIntakes.push(firstSlice);
            for(let i = currSlice; i < intakes.length; i = i + sliceSize){
                finalIntakes.push(intakes.slice(currSlice, i + sliceSize))
            }
        } else {
            finalIntakes.push(intakes);
        }
        finalIntakes.map((intakeItems, j) => {
            setTimeout(() => {
                Promise.all(intakeItems.map((intake, i) => {
                    if(i === (intakeItems.length - 1) && j === (finalIntakes.length - 1) ){
                        intake.productAmt = intakes.length;
                        intake.descriptions  = intakes.map((intake) => `$${intake.price} ${intake.title}`);
                        intake.links = intakes.map((intake) => `https://www.ctrl-mod.com/products/used-${intake.title.trim().toLowerCase().replace(/\//g, " ").replace(/\$/g,"").replace(/\–/g,"").replace(/_/g, '').replace(/\./g,"").replace(/\+/g,"").replace(/\)/g,"").replace(/\*/g,"").replace(/\(/g,"").replace(/\‘/g,"-").replace(/\s+/g, '-').replace(/\'/g,"-").replace(/\’/g,"-").replace(/-+/g,'-')}-${intake.identifier}`);
                        intake.signature = signature;
                        return intakeProductFinal({ intake })
                    } else {
                        return intakeProduct({ intake })
                    }
                }))
                .then((product) => {
                    if(JSON.parse(product[product.length - 1].config.data).product.sendEmail){
                        setError(false);
                        setSuccess(true);
                        setdisableAgree(false);
                        setErrors([]);
                        setIntakes([]);
                        document.body.scrollTop = 0; 
                        document.documentElement.scrollTop = 0; 
                    }
                })
                .catch((error) => {
                    const product = error && error.response && error.response.data && error.response.data.config && error.response.data.config.data && JSON.parse(error.response.data.config.data).product;
                    const title = product && product.title;
                    const price = product && product.variants && product.variants[0] && product.variants[0].price
                    setError(true);
                    setdisableAgree(false);
                    setErrors([...errors, `${title} at ${price} was not added`]);
                    setSuccess(false);
                    document.body.scrollTop = 0; 
                    document.documentElement.scrollTop = 0; 
                });
            },
            j * 15000)
        })

    }

    const setSign = () => {
        const file = signPad.getTrimmedCanvas().toDataURL('image/png');
        setSignature(file);
    }

    const addItem = () => {
        intake.identifier = uuidv4();
        intake.price = (+intake.price).toFixed(2)
        setIntakes([...intakes, intake]);
        const newIntake = JSON.parse(JSON.stringify(intake));
        newIntake.title = "";
        newIntake.price = "0.00";
        setIntake(newIntake);
        titleRef.current.focus();
        description.current.value = textInputDefaultValue;
    }

    const removeItem = (index) => {
        const newIntakes = intakes.filter((item, i) => {
            return i !== index
        });
        setIntakes(newIntakes);
    }
 
    return (
        <Container>
            <h1 className="m-3">Intake</h1>
            {
                (error || success) &&
                <Alert variant={success ? "success" : "danger"}>
                {
                    success ?
                    "all the items are now ready for intake. Get yourself to the home page to see them" :
                    errors.map((error) => `${error} `) 
                }
                </Alert>
            }
            <Form className="w-100 m-3">
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Customer Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="user email"
                                onChange={e => setField('user_email', e.target.value)}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Title</Form.Label>
                            <Form.Control 
                                type="text" 
                                ref={titleRef}
                                placeholder="title"
                                value={intake.title}
                                onChange={e => setField('title', e.target.value)}
                            />
                        </Col>

                        <Col>
                            <Form.Label>Price</Form.Label>
                            <CurrencyInput
                                className="form-control"
                                placeholder="$0.00"
                                decimalsLimit={2}
                                value={intake.price}
                                onKeyPress={(e) => e.key === 'Enter' && addItem()}
                                onValueChange={(value, name) => setField('price', value)}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                ref={description}
                                as="textarea"
                                onChange={e => setField('description', e.target.value)}
                                defaultValue={textInputDefaultValue}
                                value={description.current && description.current.value}
                                rows="10"
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Row className="m-1">
                    <Button onClick={addItem}>Add Product</Button>
                </Row>
                <Row className="m-3">
                    {
                        intakes && intakes.length > 0 &&
                        <Card className="m-2" style={{width: "100%"}}>
                        {
                            intakes.map((intake, i) => {
                                return <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                {i + 1}. title: {intake.title}
                                            </Col>
                                            <Col style={{textAlign:"center"}}>
                                                price: ${intake.price}
                                            </Col>
                                            <Col style={{textAlign:"right"}}>
                                                <Button style={{whiteSpace:"nowrap", padding:"2px"}} onClick={() => removeItem(i)}>Remove Product</Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            })
                        }
                        </Card>
                    }
                </Row>
                <Row className="m-2">
                    <h5>Selling Terms</h5>
                    
                    <p>
                        <p>Control and the consignor of the items listed on the attached page agree to these terms:</p>

                        <span>The items consigned are for sale for the period of this agreement. Consignor warrants that s/he/they is the owner or otherwise legally entitled to sell the items.</span>
                    </p>

                    <p>
                        Consignor warrants that to the best of their knowledge, items are as represented. 
                    </p> 

                    <p>
                        Control assumes no liability for the loss or destruction of any items caused by an event that is beyond its control.
                    </p>

                    <p>
                        Control reserves the right to cancel and return the consignment to the consignor at anytime. Should the consignor select to cancel their consignments, the consignor is responsible for the pick up of the items from the Control store on 416 Lorimer St, Ste 1L, Brooklyn, NY 11206.
                    </p>
                    <p>
                        <h5>Payment Terms:</h5>
                        <p>
                            Upon the sale of an item, consignor receives 75% of the item’s sale price in cash payout or 80% as in-store credit, excluding sales tax. Earliest payment will be made 3-7 days after date of sale as a bank check, Paypal or store credit. If consignor chooses Paypal, Consignor is responsible for all Paypal Fees. The consignor must request payment by e-mailing consign@ctrl-mod.com or calling us at 1-888-598-1990 ext. 1.
                        </p>
                    </p>

                    <p>
                        The undersigned understands and agrees to the provisions of this consignment contract in its entirety.
                    </p>

                </Row>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Label>Signature</Form.Label>
                            <SignatureCanvas
                                penColor='black'
                                canvasProps={{style: {display: 'block'}, width: 500, height: 200}}
                                ref={(ref) => { signPad = ref }}
                                onEnd={setSign}
                                backgroundColor='rgba(211, 211, 211, 1)'
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Row className="m-1" style={{paddingBottom:'25px'}}>
                    <Button disabled={disableAgree} onClick={submitIntake}>Agree</Button>
                </Row>
            </Form>
        </Container>
    )
};

export default IntakeProduct;
