import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

const Loader = () => {
    return(
        <Container>
            <Row className="justify-content-center align-items-center h-100" >
                <Spinner 
                    animation="border" 
                    role="status" 
                    style={{width:"10rem", height:"10rem"}} 
                />
            </Row>
        </Container>
    )
}

export default Loader;
